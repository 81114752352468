.Estimate-Button {
    height: 100px;
    width: 20%;
    min-width: 175px;
    background-color: #2EC0F9;
    border-color: #2EC0F9;
    color: black;
    margin-bottom: 25px;
}

.Estimate-Button:hover {
    background-color: #59B0F9;
    border-color: #59B0F9;
    color: black;
}

ul {
    text-align: left;
}

.right-align-text {
    text-align: right;
    align-self: center;
}

.left-align-text {
    text-align: left;
    align-self: center;
}

.confirm-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1750;
}

.confirm-window {
    position: relative;
    background-color: white;
    padding: 50px;
    width: 75%;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    z-index: 2000;
}

.close-button {
    position: absolute;
    width: 30px;
    right: -15px;
    top: -15px;
}

.selected-thumbnail {
    position: relative;
}

.selected-thumbnail::after {
    content: 'selected';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 20px;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 5px 10px;
    border-radius: 5px;
}

.darken {
    filter: brightness(50%);
}

.section-divider {
    border: 0;
    height: 2px;
    background-color: #333;
    margin-top: 50px;
    margin-bottom: 25px;
}

.custom-button-group {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media (max-width: 1000px) {
    .custom-button-group .Estimate-Button {
        display: block;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    .custom-button-group .Estimate-Button:first-child {
        margin-top: 0;
    }
}

@media (max-width: 575.98px) {
    .row>.col-xs-12 {
        padding-top: 0 !important;
    }
}


/*
    Baby powder: #f6f7f0
    Non Photo blue: #bff4ff
    Uranian Blue: #b1e5ff
    Light Sky Blue: #a3d5ff
    Carolina blue: #8dbee1
*/